import { FC, useContext, useMemo, useState } from "react"
import { BiSolidFilePdf, BiImage, BiFile, BiDotsHorizontalRounded, BiDownload, BiEdit, BiTrash } from "react-icons/bi"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { getFileType } from "../../../lib/fileTypeHelpers"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { DocumentPreviewDrawer } from "../Drawer/DocumentPreviewDrawer"
import { QuickMenuMui } from "../../QuickMenuMui"
import { DataGrid, GridColDef, GridRowSelectionModel, GridValueGetterParams } from "@mui/x-data-grid"
import { Box, Typography } from "@mui/material"
import { isBefore } from "date-fns"
import Pill from "../../Pill"
import { colors } from "../../../helpers/colors"
import StandardModal from "../../Modals/StandardModal"
import { TableAddButton } from "../../TableAddButton"
import { DeleteDocumentModal, PreviewDocumentModal } from "./DocumentModals"
import { ModalProps, useModalProps } from "../../../hooks/useModalProps"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonRow } from "./DocumentList.skeleton"

const { gray } = colors

export type Document = {
  id: string
  documentUrl?: string
  name: string
  uploaded: Date
  type: string
  status?: string
  expirationDate?: Date
}

type DocumentProps = {
  data: Document
  onPreview: (document: Document) => void
  flagIsEnabled?: boolean
}

export const PreviewRegExp = new RegExp(/^(jpeg|gif|png|jpg|avif|bmp|svg\+xml|tiff|webp)$/)

export const DocumentName: FC<DocumentProps> = ({ data, onPreview }) => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault()
        isMobile ? pushDrawer(<DocumentPreviewDrawer document={data} />) : onPreview(data)
      }}
      className="hover:underline"
    >
      {data.name}
    </a>
  )
}

export const RowActionMenu: FC<{
  document: Document
  onEdit: (data: Document) => void
  onDownload: (document: Document) => void
  onDelete: (document: Document) => void
}> = ({ document, onEdit, onDownload, onDelete }) => {
  return (
    <QuickMenuMui
      items={[
        [
          {
            value: "Edit",
            Icon: BiEdit,
            onClick: () => onEdit(document),
          },
          {
            value: "Download",
            Icon: BiDownload,
            onClick: () => onDownload(document),
          },
        ],
        [
          {
            value: "Delete",
            onClick: () => onDelete(document),
            Icon: BiTrash,
            color: "red",
          },
        ],
      ]}
    >
      <BiDotsHorizontalRounded className="w-6 h-6" />
    </QuickMenuMui>
  )
}

export const DocumentIcon: FC<DocumentProps> = ({ data, onPreview, flagIsEnabled = false }) => {
  const { documentUrl, name, type } = data
  const { push: pushDrawer } = useContext(DrawerContext)
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])
  const iconStyle = "p-0 text-gray-400 w-10 h-10"
  const iconPreviewStyle = "max-w-[2.5rem] max-h-[2.5rem] ml-auto mr-auto"

  return (
    <div className="max-w-[2.5rem] max-h-[2.5rem] relative cursor-pointer mt-auto mb-auto">
      <div
        className="absolute z-20 top-0 left-0 w-full h-full"
        onClick={(e) => {
          e.preventDefault()
          flagIsEnabled && isMobile ? pushDrawer(<DocumentPreviewDrawer document={data} />) : onPreview(data)
        }}
      ></div>

      {type === "pdf" ? (
        isMobile ? (
          <BiSolidFilePdf className={iconStyle} />
        ) : (
          <object data={documentUrl} className={iconPreviewStyle}>
            <BiSolidFilePdf className={iconStyle} />
          </object>
        )
      ) : type === "svg+xml" ? (
        <BiImage className={iconStyle} />
      ) : type.match(PreviewRegExp) ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img alt={name || "document preview"} className={iconPreviewStyle} src={documentUrl} />
      ) : (
        <BiFile className={iconStyle} />
      )}
    </div>
  )
}

export const supportsFilePickerApi = () => {
  return (
    "showSaveFilePicker" in window &&
    (() => {
      try {
        return window.self === window.top
      } catch {
        return false
      }
    })()
  )
}

export const downloadByDialog = async (document: Document) => {
  try {
    // browser availability: https://developer.mozilla.org/en-US/docs/Web/API/Window/showSaveFilePicker#browser_compatibility
    // @ts-ignore
    const handle = await window.showSaveFilePicker({
      suggestedName: `${document.name}.${getFileType(document.type)}`,
    })

    const blob = await (await fetch(document.documentUrl!)).blob()

    const file = new File([blob], document.name, {
      lastModified: new Date(document.uploaded).getTime(),
      type: document.type,
    })

    const writable = await handle.createWritable()
    await writable.write(file)
    await writable.close()

    return
  } catch (err: any) {
    if (err?.name !== "AbortError") {
      console.error(err.name, err.message)

      downloadByLink(document)

      return
    }
  }
}

export const downloadByLink = (document: Document) => {
  const a = window.document.createElement("a")

  a.href = document.documentUrl!
  a.download = document.name
  a.style.display = "none"
  a.target = "_blank"

  window.document.body.append(a)

  a.click()

  setTimeout(() => {
    a.remove()
  }, 1000)
}

export const getGridColumns = (
  onPreview: (document: Document) => void,
  onEdit: (data: Document) => void,
  onDownload: (document: Document) => void,
  onDelete: (document: Document) => void
) => {
  const columns: GridColDef[] = [
    {
      field: "imageUrl",
      headerName: "",
      width: 60,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <DocumentIcon data={row} onPreview={onPreview} />,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => <DocumentName data={params.row} onPreview={onPreview} />,
    },
    {
      field: "uploaded",
      headerName: "Uploaded",
      width: 120,
      type: "date",
      valueGetter: (params: GridValueGetterParams) => new Date(params.row.uploaded),
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      width: 120,
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.expirationDate ? new Date(params.row.expirationDate).toLocaleDateString() : "None",
      renderCell: (params) => (
        <Typography fontSize={14} color={params.formattedValue === "None" ? gray[400] : ""}>
          {params.formattedValue}
        </Typography>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 75,
      valueGetter: (params: GridValueGetterParams) => getFileType(params.row.type),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      sortable: false,
      valueGetter: ({ row: { expirationDate, status } }: GridValueGetterParams) => {
        if (expirationDate === "None") {
          return status
        }

        return isBefore(expirationDate, new Date().setHours(0, 0, 0, 0)) ? "expired" : status
      },
      renderCell: (params) => {
        const status = params.formattedValue
        return (
          <>
            {status === "active" && <Pill color="blue">Active</Pill>}
            {status !== "active" && <Pill color="orange">Expired</Pill>}
          </>
        )
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      editable: false,
      width: 80,
      renderCell: ({ row }) => {
        const rowProps: Document = {
          id: row.id,
          name: row.name,
          type: row.type,
          uploaded: row.uploaded,
          documentUrl: row.documentUrl,
          expirationDate: row.expirationDate,
          status: row.status,
        }

        return <RowActionMenu document={rowProps} onEdit={onEdit} onDownload={onDownload} onDelete={onDelete} />
      },
    },
  ]

  return columns
}

export type DocumentsListProps = {
  filteredRows: Document[]
  documentModalProps: ModalProps
  fetching: boolean

  refreshList: () => void
  editDocument: (document: Document | undefined) => void
}

export const DocumentsList: FC<DocumentsListProps> = ({ 
  filteredRows, 
  documentModalProps,
  fetching,
  refreshList,
  editDocument,
}) => {

  const [documentToDelete, setDocumentToDelete] = useState<Document>()
  const [previewDocument, setPreviewDocument] = useState<Document>()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const deleteDocumentModalProps = useModalProps("Delete Document")
  const previewDocumentModalProps = useModalProps("Document Preview")

  const gridColumns = useMemo(() => {
    const onPreview = (document: Document) => {
      setPreviewDocument(document)
      previewDocumentModalProps.handleOpenModal()
    }

    const onEdit = (document: Document) => {
      editDocument(document)
    }

    const onDownload = async (document: Document) => {
      if (supportsFilePickerApi()) {
        downloadByDialog(document)
      } else {
        downloadByLink(document)
      }
    }

    const onDelete = (document: Document) => {
      setDocumentToDelete(document)
      deleteDocumentModalProps.handleOpenModal()
    }

    return getGridColumns(onPreview, onEdit, onDownload, onDelete)
  }, [previewDocumentModalProps, editDocument, deleteDocumentModalProps])

  if (fetching) {
    return (
      <SkeletonContainer>
        <SkeletonRow />
      </SkeletonContainer>
    )
  }

  return (
    <>
      <Box sx={{ height: "auto", width: "100%" }}>
        <DataGrid
          autoHeight
          rows={filteredRows}
          columns={gridColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[10]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          />
      </Box>
      <StandardModal {...deleteDocumentModalProps}>
        <DeleteDocumentModal
          refreshList={refreshList}
          closeModal={deleteDocumentModalProps.handleCloseModal}
          document={documentToDelete} />
      </StandardModal>
      <PreviewDocumentModal {...previewDocumentModalProps} document={previewDocument} />
      <TableAddButton fullWidth label="Add document" onClick={documentModalProps.handleOpenModal} />
    </>
  )
}
