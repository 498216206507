/* eslint-disable @next/next/no-img-element */
import { Button } from "@mui/material"
import { FC, useContext } from "react"
import { PickPlus } from "../../../types/helpers"
import { Document } from "../Documents/DocumentsList"
import { ImageRegExp, ObjectRegExp } from "../Documents/DocumentModals"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

const FallbackUI: FC<{ document: PickPlus<Document, "documentUrl"> }> = ({ document }) => {
  const { handleClose } = useContext(SingleDrawerContext)
  return (
    <div className="flex flex-col justify-between h-full">
      <div className="p-10 flex flex-col ">
        <p className="text-xl flex-col">
          The document preview for this document type is unavailable. Would you like to download instead?
        </p>
      </div>
      <div className="rounded-b-xl flex gap-x-2.5 justify-end border-t-2 px-4 py-3.5 leading-none">
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" href={document?.documentUrl}>
          Download
        </Button>
      </div>
    </div>
  )
}

export const DocumentPreviewDrawer: FC<{
  document: PickPlus<Document, "id" | "name" | "documentUrl" | "type"> | undefined
}> = ({ document }) => {
  const isDownloadDocument = document && !(ImageRegExp.test(document?.type) || ObjectRegExp.test(document?.type))
  const { handleClose } = useContext(SingleDrawerContext)

  return (
    <>
      <DrawerHeader handleClose={handleClose} href={document?.documentUrl} />
      <DrawerBody className="h-full">
        {!!document?.type && ImageRegExp.test(document.type) && (
          <img
            alt={document?.name || "document preview"}
            className="mx-auto object-contain"
            src={document?.documentUrl}
          />
        )}
        {!!document?.type && ObjectRegExp.test(document.type) && (
          <object data={document?.documentUrl} className="h-full w-full border-none object-contain">
            <FallbackUI document={document} />
          </object>
        )}
        {isDownloadDocument && <FallbackUI document={document} />}
      </DrawerBody>
    </>
  )
}
