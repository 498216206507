import { Button } from "@mui/material"
import { FC } from "react"
import { BiPlus } from "react-icons/bi"
import { colors } from "../helpers/colors"

type Props = {
  className?: string
  fullWidth?: boolean
  label: string
  onClick: () => any
  disabled?: boolean
  padTheTop?: boolean
}

export const TableAddButton: FC<Props> = ({ label, fullWidth = true, disabled, padTheTop = true, ...props }) => {
  return (
    <Button
      sx={{
        color: colors.gray[500],
        fontSize: "14px",
        height: "32px",
        marginTop: padTheTop ? "16px" : undefined,
      }}
      startIcon={<BiPlus />}
      color="secondary"
      variant="contained"
      fullWidth={fullWidth}
      {...props}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}
