import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useFormikContext } from "formik"
import { FC } from "react"
import { BiCaretDown } from "react-icons/bi"
import { colors } from "../../../helpers/colors"
import { FileUpload } from "../../../providers/DocumentsProvider"
import { DocumentUpload } from "../../FileApi/DocumentUpload"
import { TextField } from "../../Formik/TextField"
import { MuiModal } from "../../Modals/MuiModal"
import { Document } from "./DocumentsList"
import { AddOrEditDocumentFormValues } from "../Projects/Documents/ProjectDocuments"

const { gray } = colors

type Props = {
  documentToUpdate: Document | undefined
  fileSelected: (file: FileUpload) => void
  documentName: string
  updateDocumentName: (fileName: string) => void
  clear: () => void
  submitForm?: () => void
}

export const AddOrEditDocumentModal: FC<Props> = ({
  documentToUpdate,
  fileSelected,
  documentName,
  updateDocumentName,
  clear,
  submitForm,
}) => {
  const { setFieldValue, resetForm, values, isSubmitting } = useFormikContext<AddOrEditDocumentFormValues>()

  const clearForm = () => {
    resetForm()
    clear()
  }

  const onFileSelect = (fileName: string, file: File, blob: Blob) => {
    updateDocumentName(fileName)
    fileSelected({ file, blob })
  }

  const isEditing = !!documentToUpdate

  return (
    <MuiModal
      contentLabel="Add Document"
      submitButtonText={isEditing ? "Update" : "Add"}
      isOpen={true}
      disabled={isSubmitting}
      handleCloseModal={clearForm}
      submitForm={submitForm}
    >
      {!isEditing && <DocumentUpload className="grow" onFileSelect={onFileSelect} />}
      <Grid container spacing={3} columnSpacing={2} alignContent="start">
        <Grid item xs={12} marginTop={3}>
          <TextField
            required
            name="documentName"
            label="Document Name"
            disabled={isSubmitting}
            className="w-full"
            value={documentName}
            onChange={(e) => {
              const newDocumentName = e.currentTarget.value
              updateDocumentName(newDocumentName)
              setFieldValue("documentName", newDocumentName)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              label="Status"
              disabled={isSubmitting}
              value={values.status}
              onChange={(e) => setFieldValue("status", e.target.value)}
              size="small"
              sx={{
                ".MuiOutlinedInput-notchedOutline": { borderColor: gray[400] },
                minWidth: 268,
              }}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="expired">Expired</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <DatePicker
              label="Expiration Date"
              format="MM/dd/yyyy"
              disabled={isSubmitting}
              value={values?.expirationDate || null}
              onChange={(newValue) => setFieldValue("expirationDate", newValue)}
              slots={{
                openPickerIcon: BiCaretDown,
              }}
              sx={{
                "& .MuiIconButton-edgeEnd": { width: "33px", height: "33px" },
              }}
              className="w-full"
              onError={(error) => console.error("error: ", error)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </MuiModal>
  )
}
