import { gql } from "urql"

export const UPDATE_ONE_FILE = gql`
  mutation UpdateOneFile($id:String!, $fileName: String, $status: String, $expiresAt:DateTime) {
  updateOneFile(id:$id, fileName: $fileName, status:$status, expiresAt:$expiresAt) {
    id
    fileName
    status
    expiresAt
  }
}
`

export const DELETE_ONE_FILE = gql`
  mutation DeleteOneFile($id: String!) {
    deleteOneFile(id:$id) {
      id
    }
  }
`
