import { FC, ReactNode, useEffect, useRef, useState } from "react"
import { BiFile } from "react-icons/bi"
import { classNames } from "../../helpers/classNames"
import { ImageUploadPreview } from "../ImageUploadPreview"

type Props = {
  accept?: string
  className?: string
  children?: ReactNode
  disabled?: boolean
  onFileSelect: (fileName: string, file: File, blob: Blob) => void
}

export const DocumentUpload: FC<Props> = ({ accept = "*/*", children, className, disabled = false, onFileSelect }) => {
  const dropZoneRef = useRef<HTMLLabelElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [file, setFile] = useState<File>()
  const [url, setUrl] = useState<string>()

  const handleDrop = (newFiles: FileList) => {
    if (newFiles.length) {
      const file = newFiles[0]
      const reader = new FileReader()

      reader.onloadend = async () => {
        const url = URL.createObjectURL(file)
        const documentName = file.name.split(".").slice(0, -1).join(".")
        const blob = await new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type })

        setFile(file)
        setUrl(url)

        onFileSelect(documentName, file, blob)
      }

      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    const dropZone = dropZoneRef.current
    const dragStart = (event: DragEvent) => {
      event.preventDefault()
      setIsDragging(true)
    }
    const dragEnd = (event: DragEvent) => {
      event.preventDefault()
      setIsDragging(false)
    }
    const drop = (event: DragEvent) => {
      event.preventDefault()
      setIsDragging(false)
      if (event.dataTransfer) {
        handleDrop(event.dataTransfer?.files)
      }
    }

    if (dropZone) {
      dropZone.addEventListener("dragenter", dragStart)
      dropZone.addEventListener("dragover", dragStart)
      dropZone.addEventListener("dragleave", dragEnd)
      dropZone.addEventListener("drop", drop)

      return () => {
        dropZone.removeEventListener("dragenter", dragStart)
        dropZone.removeEventListener("dragover", dragStart)
        dropZone.removeEventListener("dragleave", dragEnd)
        dropZone.removeEventListener("drop", drop)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropZoneRef?.current])

  const onDelete = () => {
    setFile(undefined)
    setUrl(undefined)
  }

  return (
    <label
      className={classNames(
        "block p-8 rounded-md bg-gray-25 border border-dashed border-gray-400 text-gray-800 text-left cursor-pointer transition-colors",
        "hover:bg-gray-50",
        isDragging ? "bg-gray-200 opacity-50" : "",
        disabled && "opacity-40 cursor-default",
        className
      )}
      ref={dropZoneRef}
    >
      <input
        disabled={disabled}
        className="hidden"
        type="file"
        accept={accept}
        onChange={(e) => {
          if (e.target.files) {
            handleDrop(e.target.files)
          }
        }}
      />
      <div className="grid grid-cols-3 justify-between items-center gap-x-5 gap-y-2">
        <PreviewImage url={url} fileType={file?.type} onDelete={onDelete} />

        <div className="flex-row col-span-2">
          {!file && (
            <>
              <p className="font-medium hidden md:block">
                Drag your document here or <span className="underline text-blue-600 hover:text-blue-700">browse</span>
              </p>
              <p className="font-medium md:hidden">
                <span className="underline text-blue-600 hover:text-blue-700">Upload document</span>
              </p>
            </>
          )}
          {file && (
            <>
              <p className="text-sm pt-1 text-gray-400">1 file to upload</p>
              <div className="text-sm pt-2 text-gray-800">
                {file.name!}
                <div className="text-xs text-gray-400">{file.type}</div>
                <div className="text-xs text-gray-400">{getFileSize(file.size)}</div>
              </div>
            </>
          )}
        </div>
        {children && <div className="col-span-3 grid grid-cols-2 md:grid-cols-4 gap-2">{children}</div>}
      </div>
    </label>
  )
}

const PreviewImage: FC<{
  url: string | undefined
  fileType: string | undefined
  onDelete: () => void
}> = ({ url, fileType, onDelete }) => {
  const isImage = fileType && fileType.indexOf("image/") >= 0
  const isPdf = fileType && fileType.indexOf("application/pdf") >= 0

  if (url && isImage) {
    return <ImageUploadPreview imageSrc={url} onDelete={onDelete} className="h-24 w-24" />
  }

  if (url && isPdf) {
    return <object data={url} width="96" height="96" />
  }

  return <BiFile className="mx-auto w-24 h-24 text-gray-300" />
}

const getFileSize = (fileSize: number) => {
  if (fileSize < 1000000) {
    return `${(fileSize / 1000 || 0).toFixed(0)} Kb`
  }

  return `${(fileSize / 1000000 || 0).toFixed(2)} Mb`
}
