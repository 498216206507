import { IconButton, Modal, Tooltip, Typography } from "@mui/material"
import { FC, createContext, useEffect, useState } from "react"
import { BiExpand, BiX } from "react-icons/bi"
import Carousel from "react-material-ui-carousel"
import { useMutation } from "urql"
import { DELETE_ONE_FILE } from "../../../graphql/queries/file.queries"
import { classNames } from "../../../helpers/classNames"
import { ModalProps } from "../../../hooks/useModalProps"
import { PickPlus } from "../../../types/helpers"
import { ButtonFilled, ButtonHollow, P } from "../../Elements"
import { ModalFooter } from "../../Modals/ModalFooter"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { Document } from "./DocumentsList"
import { GroupedObject } from "../Summary/helpers/groupByDate"

export const ImageRegExp = new RegExp(/^(jpeg|gif|png|jpg|svg\+xml|webp)$/)
export const ObjectRegExp = new RegExp(/^(pdf|mp4|ogg)$/)

type DeleteProps = {
  document: PickPlus<Document, "id" | "name"> | undefined
  closeModal: () => void
  refreshList: () => void
}

type PreviewProps = {
  document: PickPlus<Document, "id" | "name" | "documentUrl" | "type" | "uploaded"> | undefined
  images?: GroupedObject[]
  initialSlideIndex?: number
} & ModalProps

type ContextProps = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

type DocumentViewerProps = PickPlus<Document, "documentUrl" | "name" | "type">

type HeaderProps = PickPlus<PreviewProps, "images" | "document" | "handleCloseModal"> & {
  isDownloadDocument: boolean | undefined
}
type CarouselProps = PickPlus<PreviewProps, "images" | "initialSlideIndex">

export const ModalLoadingContext = createContext<ContextProps>({} as ContextProps)

export const PreviewDocumentModal: FC<PreviewProps> = ({
  document,
  handleCloseModal,
  isOpen,
  images,
  initialSlideIndex,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const isDownloadDocument =
    document && !(ImageRegExp.test(document.type) || ObjectRegExp.test(document.type)) && !images

  return (
    <Modal open={isOpen} onClose={isLoading ? () => {} : handleCloseModal}>
      <div
        className={classNames(
          "absolute top-1/2	left-1/2 translate-x-[-50%] translate-y-[-50%] rounded-t-xl rounded-b-2xl bg-black "
        )}
      >
        <ModalLoadingContext.Provider value={{ isLoading, setIsLoading }}>
          <ModalHeader
            images={images}
            document={document}
            handleCloseModal={handleCloseModal}
            isDownloadDocument={isDownloadDocument}
          />
          <main className=" w-[60vw] h-fit bg-none">
            {/* Model content for Documents Tab */}
            {document && (
              <DocumentViewer name={document.name} documentUrl={document.documentUrl} type={document.type} />
            )}

            {/* Modal content for Image carousel */}
            {images && images.length > 0 && (
              <ImageCarouselViewer images={images} initialSlideIndex={initialSlideIndex} />
            )}

            {isDownloadDocument && (
              <>
                <div className="p-10 flex flex-col">
                  <P className="flex-col text-white">
                    The document preview for this document type is unavailable. Would you like to download instead?
                  </P>
                </div>
              </>
            )}
          </main>
        </ModalLoadingContext.Provider>
      </div>
    </Modal>
  )
}

export const DeleteDocumentModal: FC<DeleteProps> = ({ closeModal, refreshList, document }) => {
  const [_, deleteDocument] = useMutation(DELETE_ONE_FILE)

  const onDelete = () => {
    deleteDocument({
      id: document?.id,
    }).then((result) => {
      result.error ? errorSnack("There was a problem deleting the document") : successSnack("Document deleted")
      refreshList()
      closeModal()
    })
  }

  return (
    <div className="p-6 flex flex-col justify-items-stretch gap-y-6">
      <p>
        Are you sure you want to delete the document <span className="font-semibold">{document?.name}?</span>
      </p>
      <ModalFooter>
        <ButtonHollow color="secondary" onClick={closeModal} type="button">
          Cancel
        </ButtonHollow>
        <ButtonFilled color="error" onClick={onDelete} type="button">
          Delete
        </ButtonFilled>
      </ModalFooter>
    </div>
  )
}

const ModalHeader: FC<HeaderProps> = ({ images, document, handleCloseModal, isDownloadDocument }) => {
  const iconSize = "w-6 h-6"
  return (
    <header className="w-full h-16 px-5 flex items-center  rounded-t-xl bg-white justify-between text-xl md:text-2xl font-medium truncate">
      <Typography variant="h3" className="truncate">
        {isDownloadDocument ? "Download Document" : images ? "Image Preview" : "Document Preview"}
      </Typography>
      <div>
        <Tooltip title="Open in new tab" arrow>
          <IconButton
            onClick={() => {
              window.open(document?.documentUrl)
            }}
          >
            <BiExpand className={iconSize} />
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleCloseModal}>
          <BiX className={iconSize} />
        </IconButton>
      </div>
    </header>
  )
}

const DocumentViewer: FC<DocumentViewerProps> = ({ type, documentUrl, name }) => {
  return (
    !!type && (
      <main className="rounded-b-xl">
        {ImageRegExp.test(type) && (
          // eslint-disable-next-line @next/next/no-img-element
          <img alt={name || "document preview"} className="mx-auto object-contain" src={documentUrl} />
        )}
        {!!type && ObjectRegExp.test(type) && (
          <object data={documentUrl} name={name} className="h-full w-full border-none object-contain"></object>
        )}
      </main>
    )
  )
}
const ImageCarouselViewer: FC<CarouselProps> = ({ images, initialSlideIndex }) => {
  // State variable to track whether the carousel is ready to display all images
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    setIsReady(false)

    /* Set a timeout to update isReady after a half-second delay, allowing the carousel to initially render with the correct image visible,while hiding the others temporarily to prevent flicker during transition. */
    const timeout = setTimeout(() => {
      setIsReady(true)
    }, 500)

    return () => clearTimeout(timeout)
  }, [initialSlideIndex])

  return (
    <main>
      <Carousel
        autoPlay={false}
        animation="slide"
        swipe={false}
        navButtonsAlwaysVisible
        index={initialSlideIndex}
        strictIndexing
        indicatorContainerProps={{
          style: {
            position: "fixed",
          },
        }}
      >
        {images &&
          images.map((img, i) => (
            <div
              key={i}
              className={`flex justify-center items-center h-[60vh] ${
                !isReady && i !== initialSlideIndex ? "invisible" : ""
              }`}
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={img.documentUrl}
                alt={`Image ${i} uploaded by ${img.reporterName} at ${img.uploaded}`}
                loading="lazy"
                className="object-contain max-h-[60vh] max-w-[60vw]"
              />
            </div>
          ))}
      </Carousel>
    </main>
  )
}
