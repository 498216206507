import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from "react"
import { ModalProps, useModalProps } from "../hooks/useModalProps"

export type Document = {
  id: string
  documentUrl?: string
  name: string
  uploaded: Date
  type: string
  status?: string
  expirationDate?: Date
}

export type FileUpload = {
  file: File
  blob: Blob
}

type ContextValue = {
  fileSelected: (file: FileUpload) => void
  fileRemoved: () => void
  editDocument: (document: Document | undefined) => void
  refreshList: () => void
  onCompleteRefresh: () => void
  clear: () => void
  setStatus: Dispatch<SetStateAction<string>>
  setExpirationDate: Dispatch<SetStateAction<Date | undefined>>
  updateDocumentName: Dispatch<SetStateAction<string>>
  setSearchText: Dispatch<SetStateAction<string>>

  documentToUpdate?: Document
  selectedFileForUpload?: FileUpload
  startRefresh: boolean
  documentName: string
  status: string
  expirationDate?: Date
  documentModalProps: ModalProps
  searchText: string
}

export const DocumentsContext = createContext<ContextValue>({} as ContextValue)

export const DocumentsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [documentToUpdate, setDocumentToUpdate] = useState<Document>()
  const [selectedFileForUpload, setSelectedFileForUpload] = useState<FileUpload>()
  const [startRefresh, setStartRefresh] = useState(false)
  const [documentName, updateDocumentName] = useState("")
  const [searchText, setSearchText] = useState("")
  const [status, setStatus] = useState("")
  const [expirationDate, setExpirationDate] = useState<Date>()
  const documentModalProps = useModalProps("Add Document")

  const fileSelected = (file: FileUpload) => {
    setSelectedFileForUpload(file)
  }

  const fileRemoved = () => {
    setSelectedFileForUpload(undefined)
  }

  const editDocument = (document: Document | undefined) => {
    setDocumentToUpdate(document)
    updateDocumentName(document?.name || "")
    documentModalProps.handleOpenModal()
  }

  const refreshList = () => {
    setStartRefresh(true)
    clear()
  }

  const onCompleteRefresh = () => {
    setStartRefresh(false)
  }

  const clear = () => {
    setDocumentToUpdate(undefined)
    setSelectedFileForUpload(undefined)
    updateDocumentName("")
    setStatus("active")
    setExpirationDate(undefined)

    documentModalProps.handleCloseModal()
  }

  return (
    <DocumentsContext.Provider
      value={{
        fileSelected,
        fileRemoved,
        editDocument,
        refreshList,
        onCompleteRefresh,
        setStatus,
        clear,
        setExpirationDate,
        updateDocumentName,
        setSearchText,

        documentName,
        documentToUpdate,
        selectedFileForUpload,
        startRefresh,
        status,
        expirationDate,
        documentModalProps,
        searchText
      }}
    >
      {children}
    </DocumentsContext.Provider>
  )
}