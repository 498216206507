import { Dispatch, FC, ReactNode, SetStateAction } from "react"
import { RenderIf } from "../../RenderIf"
import Error from "next/error"
import * as Yup from "yup"
import { H4 } from "../../Elements"
import { SearchBar } from "../../Elements/SearchBar"
import { Button, Dialog, DialogTitle } from "@mui/material"
import { BiPlus } from "react-icons/bi"
import { ModalProps } from "../../../hooks/useModalProps"
import { Formik, Form, FormikHelpers } from "formik"
import { AddOrEditDocumentModal } from "./AddOrEditDocumentModal"
import { Document, FileUpload } from "../../../providers/DocumentsProvider"
import { AddOrEditDocumentFormValues } from "../Projects/Documents/ProjectDocuments"

type DocumentsLayoutProps = {
  title: string,
  documentModalProps: ModalProps,
  documentName: string,
  documentToUpdate: Document | undefined,
  children: ReactNode,

  fileSelected: (file: FileUpload) => void,
  onSearchBarChange: (text: string) => void,
  onSearchBarCancel: () => void,
  clear: () => void,
  handleSubmit: (values: AddOrEditDocumentFormValues, formikProps: FormikHelpers<{ documentName: string, status: string, expirationDate: Date | undefined}>) => Promise<void>,
  updateDocumentName: Dispatch<SetStateAction<string>>
}

export const DocumentsLayout: FC<DocumentsLayoutProps> = ({ 
  title,
  documentModalProps,
  documentName,
  documentToUpdate,
  fileSelected,
  onSearchBarChange,
  onSearchBarCancel, 
  clear,
  handleSubmit,
  updateDocumentName,
  children
}) => {
  return (
    <RenderIf permissionsInclude="user:read" fallbackComponent={<Error statusCode={404} />}>
      <H4>{title}</H4>
      <div className="flex flex-row justify-between">
        <SearchBar onChange={onSearchBarChange} onCancel={onSearchBarCancel} />
        <Button
          variant="contained"
          color="secondary"
          endIcon={<BiPlus />}
          disableElevation
          className="text-left text-gray-800 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all cursor-pointer px-4"
          onClick={documentModalProps.handleOpenModal}
        >
          Add document
        </Button>
      </div>
    <div className="mt-10">
      {children}
    </div>

    <Dialog open={documentModalProps.isOpen} onClose={clear} scroll="paper">
      <DialogTitle>{`${!!documentToUpdate ? "Edit" : "Add"} Document`}</DialogTitle>
      <Formik
        enableReinitialize
        initialTouched={{ documentName: true }}
        validationSchema={Yup.object().shape({
          documentName: Yup.string()
            .trim()
            .required("Required")
            .label("File name")
            .test("validData", "Name required", (name) => !!name?.length),
        status: Yup.string().trim().required("Required").label("Status"),
        expirationDate: Yup.date().nullable().notRequired().default(undefined)
      })}
      initialValues={{
        documentName: documentName || documentToUpdate?.name || "",
        status: documentToUpdate?.status || "active",
        expirationDate: documentToUpdate?.expirationDate
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <AddOrEditDocumentModal 
            documentToUpdate={documentToUpdate}
            fileSelected={fileSelected}
            documentName={documentName}
            updateDocumentName={updateDocumentName}
            submitForm={submitForm}
            clear={clear} />
        </Form>
        )}
        </Formik>
      </Dialog>
    </RenderIf>
  )
}